import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
// eslint-disable-next-line no-unused-vars
import { InsightsFragment, PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence, useAnimation } from "framer-motion"
import { useMediaQuery } from "react-responsive"

import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
  connectCurrentRefinements,
  connectInfiniteHits,
  connectRefinementList,
  connectSearchBox,
  connectStateResults,
  Configure,
  InstantSearch,
} from "react-instantsearch-dom"
import { useDebounce } from "components/hooks"
import { filterHit, buildSearchClient, getUserToken } from "utils/algolia"

import InsightsCard from "components/insights-card"
import IconClose from "images/icons/close.inline.svg"
import IconSearch from "images/icons/search-gray.svg"
import Layout from "components/layout"
import SEO from "components/seo"
import InsightsCategories from "components/insights-categories"
import NewsletterForm from "components/sections/newsletter-form"
import HeaderBG from "images/hero/insights.jpg"
import SwitchArrow from "components/switch-arrow"
import GenresIcon from "images/icons/search/genres.svg"
import IndustriesIcon from "images/icons/search/industries.svg"
import MarketsIcon from "images/icons/search/markets.svg"
import MetricsIcon from "images/icons/search/metrics.svg"
import PlatformsIcon from "images/icons/search/platforms.svg"
import ShowsIcon from "images/icons/search/shows.svg"
import SolutionsIcon from "images/icons/search/solutions.svg"
import TalentIcon from "images/icons/search/talent.svg"
import MoviesIcon from "images/icons/search/movies.svg"
import FeaturedPressCarousel from "components/featured-press-carousel"

import { getSharePreviewUrls } from "utils/share-preview"

const createQueryString = (state, setPage = true) => {
  const search = {}
  if (state.query) {
    search.query = state.query
  }
  if (state.refinementList) {
    search.refinementList = Object.keys(state.refinementList)
      .filter(
        (attribute) =>
          attribute !== "categories" &&
          state.refinementList[attribute] &&
          state.refinementList[attribute].length > 0
      )
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: state.refinementList[key],
        }
      }, {})
  }
  if (state.page && setPage) {
    search.page = state.page
  }
  return qs.stringify(search)
}

const createURL = (state, categories) => {
  const queryString = createQueryString(state)
  const category = state.refinementList?.categories
    ? state.refinementList.categories[0]
    : null
  const categoryData = categories.find((c) => c.name === category)
  const slug = categoryData ? categoryData.slug : null

  return `/insights/${category ? `category/${slug}/` : ""}${
    queryString ? "?" : ""
  }${queryString}`
}

const urlToSearchState = (url, category) => {
  const { search } = url
  const newState = qs.parse(search.slice(1))
  if (category) {
    if (!newState["refinementList"]) {
      newState.refinementList = {}
    }

    newState.refinementList.categories = [category.name]
  }
  return newState
}

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const searchClient = buildSearchClient(algoliaClient)

const VirtualRefinementList = connectRefinementList(() => null)

const ClearSearch = connectCurrentRefinements(({ items, refine, onClick }) => {
  function clear() {
    if (onClick) {
      onClick()
    }
    refine(items)
  }
  return (
    <motion.button
      onClick={clear}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="clearsearch"
      style={{
        position: "absolute",
        left: 15,
        top: "calc(50% - 10px)",
        outline: "none",
      }}
    >
      <IconClose height="20" width="20" fill="#000000" />
    </motion.button>
  )
})

const Tag = styled.a`
  ${tw`bg-grey-65 bg-opacity-25 text-white font-display font-bold ml-1 mb-2 border border-grey-65 border-opacity-0 flex flex-row justify-between items-center flex-nowrap px-2`}
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  height: 28px;
  padding-top: 2px;
  text-transform: uppercase;
  transition: all 250ms;
  white-space: nowrap;

  @media (min-width: 640px) {
    font-size: 12px;
    height: 32px;
  }

  &.applied,
  &:hover {
    ${tw`border-neonred bg-neonred bg-opacity-100 border-opacity-100`}

    box-shadow: 0 2px 10px 0 rgba(227, 40, 76, 0.5);
  }

  &.applied {
    background-color: #711325;
  }

  .attribute {
    ${tw`text-neonred-light`}
    padding-right: 0.15rem;
  }

  .close {
    height: 11px;
    width: 11px;
    display: inline-block;
    margin-top: -2px;
    margin-left: 8px;
  }
`

const ExpandButtonStyle = styled.button`
  ${tw`font-display font-bold p-2 ml-1 mb-2 outline-none`}
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  height: 28px;
  text-transform: uppercase;
  transition: all 200ms;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  @media (min-width: 640px) {
    font-size: 12px;
    height: 32px;
  }
`

const ExpandButton = ({ children, className, expanded = false, ...rest }) => (
  <ExpandButtonStyle className={className} {...rest}>
    {children}
    <SwitchArrow active={expanded} />
  </ExpandButtonStyle>
)

const ToggleTag = ({
  attribute,
  query,
  label,
  currentRefinement,
  onChange,
}) => {
  const isCurrentRefinement = attribute
    ? currentRefinement && currentRefinement[attribute]
      ? currentRefinement[attribute].some(
          (r) => r.toLowerCase() === label.toLowerCase()
        )
      : false
    : query?.toLowerCase() === label?.toLowerCase()

  function flip() {
    onChange &&
      onChange({
        attribute: attribute,
        refinement: label,
        applied: !isCurrentRefinement,
      })
  }

  return (
    <Tag className={isCurrentRefinement ? "applied" : ""} onClick={flip}>
      {label}
    </Tag>
  )
}

const RefinementList = connectRefinementList(
  ({ items, refine, attribute, icon, last = false, ...rest }) => {
    const [expanded, setExpanded] = useState(false)
    const [wrapped, setWrapped] = useState(false)
    const tagsWrapper = useRef(null)

    function onExpandClick(event) {
      if (event) {
        event.preventDefault()
      }
      setExpanded(!expanded)
    }

    useEffect(() => {
      if (tagsWrapper.current) {
        const tags = Array.from(tagsWrapper.current.children)
        // Detect if there are more tags than filter on screen
        // By checking if there are more than 1 "top" values
        const rows = tags.reduce((acc, tag) => {
          const r = tag.getBoundingClientRect()
          acc.add(r.top)
          return acc
        }, new Set()).size
        setWrapped(rows > 1)
      }
    }, [tagsWrapper, items])

    if (items.length) {
      return (
        <>
          <div
            className={`py-2 m:py-4 flex flex-wrap m:flex-row m:flex-nowrap justify-between items-start w-full flex-grow-0 flex-shrink-0 overflow-hidden ${
              last ? "" : "m:pb-3"
            }`}
          >
            <div className="h-10 uppercase font-display text-xs flex flex-row items-center justify-start text-grey-95 w-32 pb-1 m:pr-2 flex-shrink-0 flex-grow-0">
              <img
                src={icon}
                height={16}
                width={16}
                alt=""
                className="mr-2 pb-1 opacity-50"
              />
              {attribute}
            </div>
            <div
              className={`flex flex-wrap justify-start items-start overflow-hidden flex-grow-1 w-full -ml-1 m:ml-0 ${
                expanded ? "" : "h-16 m:h-10"
              }`}
              ref={tagsWrapper}
            >
              {items.map((item) => (
                <Tag
                  className={item.isRefined ? "applied" : ""}
                  key={item.label}
                  onClick={(event) => {
                    event.preventDefault()
                    refine(item.value)
                  }}
                >
                  {item.label}{" "}
                  <span className="font-body opacity-75 pl-1">
                    ({item.count})
                  </span>
                </Tag>
              ))}
            </div>
            <div className="flex flex-row justify-start m:justify-end pr-2 m:w-24 flex-shrink-0 -ml-2 mt-2 m:ml-0 m:mt-0">
              {wrapped && (
                <ExpandButton
                  className="text-white opacity-75"
                  expanded={expanded}
                  onClick={onExpandClick}
                >
                  {expanded ? "Less" : "More"}
                </ExpandButton>
              )}
            </div>
          </div>
          {!last && <hr />}
        </>
      )
    } else {
      return null
    }
  }
)

const CurrentRefinements = connectCurrentRefinements(({ items, refine }) => {
  function singular(attribute) {
    const attr = attribute.substring(0, attribute.length - 2)
    if (attr === "industries") {
      return "industry:"
    } else {
      return `${attr.substring(0, attr.length - 1)}:`
    }
  }

  if (items.length) {
    return (
      <div className="bg-black bg-opacity-50">
        <div className="flex flex-wrap justify-start m:justify-center items-start pt-2 m:pt-6 mx-auto max-w-s m:max-w-m l:max-w-l w-full">
          <div className="h-10 uppercase font-display text-xs flex flex-col items-start m:items-end justify-center text-grey-95 pb-1 pl-1 m:pl-0 m:pr-2 w-full m:w-32">
            Selected tags:
          </div>
          {items.map((attr) => {
            if (attr.items) {
              return attr.items.map((item, idx) => (
                <React.Fragment key={idx}>
                  <Tag
                    className="applied"
                    onClick={(event) => {
                      event.preventDefault()
                      refine(item.value)
                    }}
                  >
                    <span className="attribute">{singular(attr.label)}</span>{" "}
                    {item.label}
                    <IconClose className="close" />
                  </Tag>
                </React.Fragment>
              ))
            } else {
              return (
                <Tag
                  className="applied"
                  onClick={(event) => {
                    event.preventDefault()
                    refine(attr.value)
                  }}
                >
                  <span className="attribute">{singular(attr.attribute)}:</span>{" "}
                  {attr.label}
                  <IconClose className="close" />
                </Tag>
              )
            }
          })}
        </div>
      </div>
    )
  } else {
    return null
  }
})

const SearchInput = styled.input`
  ${tw`font-italic italic text-grey-5 outline-none`}
  background-color: rgba(255,255,255,1);
  background-image: url(${IconSearch});
  background-position: left 15px top 50%;
  background-repeat: no-repeat;
  background-size: 20px auto;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  font-weight: 300;
  height: 46px;
  max-weight: 100% !important;
  padding-left: 42px;
  padding-right: 10px;
  padding-top: 2px;
  transition: all 200ms;
  white-space: nowrap;

  &.active {
    ${tw`text-grey-5 outline-none`}
    background-image: none;
    padding-left: 42px;
  }

  &::placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const SearchInputPlaceholder = styled.div`
  ${tw`font-italic italic absolute max-w-s m:max-w-m l:max-w-l`}
  border-radius: 5px;
  font-size: 15px;
  font-weight: 300;
  height: 0px;
  opacity: 0;
  padding-left: 42px;
  padding-right: 12px;
  padding-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const SearchBox = connectSearchBox(
  ({ value, refine, placeholder, ...rest }) => {
    const debouncedQuery = useDebounce(value, 300)
    const [inputWidth, setInputWidth] = useState(null)
    const placeholderCalc = useRef(null)

    useEffect(
      () => {
        if (debouncedQuery) {
          refine(debouncedQuery)
        }
      },
      [debouncedQuery, refine] // Only call effect if debounced search term changes
    )

    useEffect(() => {
      if (placeholderCalc.current) {
        const dimensions = placeholderCalc.current.getBoundingClientRect()
        setInputWidth(dimensions.width)
      }
    }, [placeholderCalc])

    return (
      <>
        <SearchInput
          autoComplete="off"
          delay={500}
          id="insights-search"
          name="insights-search"
          placeholder={
            placeholder || "Try searching: The Mandalorian or Travelability"
          }
          style={{ width: inputWidth }}
          type="text"
          value={value}
          {...rest}
        />
        <SearchInputPlaceholder ref={placeholderCalc}>
          {placeholder || "Try searching: The Mandalorian or Travelability"}
        </SearchInputPlaceholder>
      </>
    )
  }
)

const Hits = connectInfiniteHits(
  ({
    hits,
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,
    settings,
    ...rest
  }) => {
    const [scrollTo, setScrollTo] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const insightsConfig = settings?.insightsConfig?.jsonValue
    const fallbackResult = {
      title: insightsConfig.fallbackTitle,
      excerpt: insightsConfig.fallbackExcerpt,
      path: insightsConfig.fallbackLinkTo,
      featuredImage: { url: insightsConfig.fallbackImage },
      linkCaption: insightsConfig.fallbackLinkCaption,
    }

    function loadCall(callback, event) {
      if (event) {
        event.preventDefault()
      }
      setLoadingState(true)
      setScrollTo({ x: window.scrollX, y: window.scrollY })
      callback()
    }

    function loadMore(event) {
      loadCall(refineNext, event)
    }

    function loadPrevious(event) {
      loadCall(refinePrevious, event)
    }

    // Restore scroll position when new hits are loaded
    useEffect(() => {
      if (scrollTo !== null) {
        window.scrollTo(scrollTo.x, scrollTo.y)
        setScrollTo(null)
      }
      setLoadingState(false)
    }, [hits])

    return (
      <div className="mx-auto max-w-s m:max-w-m l:max-w-l">
        {!!hasPrevious && (
          <div className="px-2 m:px-0">
            <button
              className=" bg-white py-2 pt-3 px-0 text-center uppercase font-display text-grey-45 m-1 block w-full outline-none"
              disabled={loadingState}
              onClick={loadPrevious}
            >
              {loadingState ? "Loading..." : "Load previous"}
            </button>
          </div>
        )}
        <div className="flex flex-wrap m:-mx-2 overflow-display">
          {hits.map((hit) => {
            var post = filterHit(hit, settings)
            return <InsightsCard post={post} key={hit.objectID} wide />
          })}
          {hits.length === 0 && (
            <InsightsCard post={fallbackResult} key="insights-fallback" wide />
          )}
        </div>
        {!!hasMore && (
          <div className="px-2 m:px-0">
            <button
              className="mb-8 bg-white py-2 pt-3 px-0 text-center uppercase font-display text-grey-45 block w-full outline-none"
              onClick={loadMore}
              disabled={loadingState}
            >
              {loadingState ? "Loading..." : "Load more"}
            </button>
          </div>
        )}
      </div>
    )
  }
)

const ResultsCount = connectStateResults(({ searchResults }) => {
  const controls = useAnimation()
  const count = searchResults?.nbHits || 0

  function showResults(event) {
    event && event.preventDefault()
    const catNav = document.getElementById("categories-nav")
    if (catNav) {
      const margin = window.innerWidth > 640 ? 82 : 50
      window.scrollTo({
        left: 0,
        top: catNav.offsetTop - margin,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    controls.start({
      opacity: [0, 0, 1],
      transition: { duration: 0.5 },
    })
  }, [count])

  return (
    <div className="bg-black bg-opacity-50">
      <AnimatePresence>
        <motion.a
          className="flex flex-wrap justify-start m:justify-center items-start py-2 pb-3 mx-auto max-w-s m:max-w-m l:max-w-l w-full text-white font-italic italic text-sm m:text-base"
          animate={controls}
          initial={{ opacity: 0 }}
          onClick={showResults}
          href="#categories-nav"
        >
          <strong className="pr-1">{count}</strong>insights found - see results
          <SwitchArrow active={false} style={{ marginTop: 6 }} />
        </motion.a>
      </AnimatePresence>
    </div>
  )
})

const LoadingIndicator = connectStateResults(
  ({ isSearchStalled, searchResults, noResults, loading }) => {
    if (isSearchStalled) {
      return (
        <div className="text-center w-full py-16">
          {loading || "Loading..."}
        </div>
      )
    } else {
      return null
    }
  }
)

const Header = styled.div`
  ${tw`pt-20 m:pt-40`}
  background: linear-gradient(269deg,rgba(43, 17, 67, .85) 0%,rgba(0, 0, 0, .85) 100%), url(${HeaderBG}) center center no-repeat;
  background-size: cover;
`

const HeaderTitle = styled.h1`
  ${tw`py-2 pb-6 w-full font-display font-bold text-white text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const ResultsTitle = styled.h2`
  ${tw`py-4 pt-8 px-2 font-display text-black text-center l:text-left max-w-s m:max-w-m mx-auto`}
  font-size: 28px;
`

const Main = styled.div`
  ${tw`mx-auto l:max-w-l`}
`

const Insights = ({ data, pageContext }) => {
  const page = data.gcms.pages[0]
  const suggested = data.gcms.suggestedTags
  const categories = data.gcms.insightsCategories
  const { category } = pageContext
  const DEBOUNCE_TIME = 500
  const location = useLocation()
  const urlState = urlToSearchState(location, category)
  const [searchState, setSearchState] = useState(urlState)
  const [query, setQuery] = useState(urlState.query || "")
  const [debouncedSetState, setDebouncedSetState] = useState(null)
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [showRefinements, setShowRefinements] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const [hasRefinements, setHasRefinements] = useState(false)
  const [refinements, setRefinements] = useState({})
  const [htmlTitle, setHtmlTitle] = useState(page.title)
  const [resultsTitle, setResultsTitle] = useState(
    category?.name || "Demand powered insights"
  )
  const settings = data.gcms?.insightsConfig?.jsonValue

  const isMobile = useMediaQuery({ maxWidth: 640 })

  const headerRef = useRef()

  useEffect(() => {
    updateSearchState(urlState)
  }, [location])

  useEffect(() => {
    const tagRefinement = getSingleTagRefinement()

    const category = searchState.refinementList?.categories?.[0]

    let htmlTitle = page.title
    let resultsTitle = category || "Demand powered insights"

    if (tagRefinement !== null) {
      htmlTitle = `${tagRefinement} ${htmlTitle}`
      resultsTitle += ` about ${tagRefinement}`
    }

    setHtmlTitle(htmlTitle)
    setResultsTitle(resultsTitle)
  }, [refinements])

  function updateSearchState(newSearchState) {
    const hasRefinements =
      newSearchState.refinementList &&
      Object.keys(newSearchState.refinementList).some(
        (r) => newSearchState.refinementList[r].length > 0
      )
    const refinements = Object.keys(newSearchState.refinementList || {})
      .filter((attr) => newSearchState.refinementList[attr].length > 0)
      .reduce((prev, attr) => {
        prev[attr] = newSearchState.refinementList[attr]
        return prev
      }, {})
    const active =
      (newSearchState.query && newSearchState.query.length > 0) ||
      hasRefinements ||
      showRefinements
    setHasRefinements(hasRefinements)
    setRefinements(refinements)
    setSearchActive(active)
    if (!active) {
      setShowRefinements(false)
    }
    setShowSuggestions(newSearchState.query && newSearchState.query.length > 0)
    setSearchState(newSearchState)
    if (newSearchState.query !== query) {
      setQuery(newSearchState.query || "")
    }
  }

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetState)
    setDebouncedSetState(
      setTimeout(() => {
        const url = createURL(updatedSearchState, categories)
        window.history.replaceState({}, null, url)
      }, DEBOUNCE_TIME)
    )
    updateSearchState(updatedSearchState)
  }

  function onQueryChange(event) {
    const value = event.currentTarget.value
    setQuery(value)
    // Query was cleared within field
    if (value.length === 0) {
      const newSearchState = searchState
      newSearchState.query = ""
      updateSearchState(newSearchState)
      setShowSuggestions(true)
    }
  }

  async function refineToggle(filter) {
    let newState = searchState
    if (!filter.attribute) {
      if (filter.applied) {
        newState.query = filter.refinement
      } else {
        newState.query = ""
      }
    } else {
      let refinements = new Set(
        searchState.refinementList &&
        searchState.refinementList[filter.attribute]
          ? searchState.refinementList[filter.attribute]
          : []
      )
      if (filter.attribute === "categories") {
        if (filter.applied) {
          refinements = [filter.refinement]
        } else {
          refinements = []
        }
      } else {
        if (filter.applied) {
          refinements.add(filter.refinement)
        } else {
          refinements.delete(filter.refinement)
        }
      }
      if (!newState["refinementList"]) {
        newState.refinementList = {}
      }
      newState.refinementList[filter.attribute] = [...refinements]
    }
    if (filter.resetPage) {
      delete newState.page
      if (headerRef.current) {
        const headerBox = headerRef.current.getBoundingClientRect()
        const offset = isMobile ? 52 : 80
        window.scrollTo({
          top: headerBox.height - offset,
          behavior: "smooth",
        })
      }
    }
    setSearchState(newState)
    onSearchStateChange(newState)
  }

  function onSearchFocus() {
    setShowSuggestions(true)
  }

  function onSearchBlur() {
    setTimeout(() => setShowSuggestions(false), 150)
  }

  function switchRefinements(event) {
    if (event) {
      event.preventDefault()
    }
    if (!showRefinements) {
      setSearchActive(true)
    }
    setShowRefinements(!showRefinements)
  }

  function clearAll() {
    setHasRefinements(false)
    setQuery("")
    setSearchActive(false)
    setShowSuggestions(false)
    setShowRefinements(false)
  }

  function scrollToTop(event) {
    event && event.preventDefault()
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  function getSingleTagRefinement() {
    const tagKeys = Object.keys(refinements).filter(
      (attr) => attr !== "categories"
    )
    if (tagKeys.length === 1 && refinements[tagKeys[0]].length === 1) {
      return refinements[tagKeys[0]][0]
    } else {
      return null
    }
  }

  const variants = {
    open: { opacity: 1, height: "auto" },
    closed: { opacity: 0, height: 0, transition: { duration: 0.1 } },
  }

  return (
    <Layout>
      {!!page && (
        <SEO
          title={htmlTitle}
          description={page.description}
          path={location.pathname + location.search}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        searchState={searchState}
      >
        <Configure
          distinct
          clickAnalytics
          enablePersonalization={true}
          userToken={getUserToken()}
          // filters={searchActive ? "" : "recordType:Article"}
        />
        {!!category && (
          <VirtualRefinementList
            attribute="categories"
            defaultRefinement={[category.name]}
          />
        )}

        <Header
          className={showRefinements || hasRefinements ? "" : "pb-16"}
          ref={headerRef}
        >
          <HeaderTitle>Global Industry Insights</HeaderTitle>
          <AnimatePresence>
            <div className="flex flex-row justify-center mx-auto max-w-s m:max-w-m l:max-w-l">
              <div className="relative">
                <SearchBox
                  className={
                    showSuggestions || searchActive ? "active" : "inactive"
                  }
                  onChange={onQueryChange}
                  onFocus={onSearchFocus}
                  onBlur={onSearchBlur}
                  value={query}
                  placeholder={settings?.placeholder}
                />
                {(showSuggestions || searchActive) && (
                  <ClearSearch clearsQuery onClick={clearAll} />
                )}
              </div>
            </div>
            {suggested && (
              <div
                className="flex flex-wrap justify-center pt-4 pb-2"
                key="search-suggestions"
              >
                {suggested.map((r, idx) => (
                  <ToggleTag
                    key={idx}
                    label={r.searchQuery}
                    attribute={r.filter ? r.filter.toLowerCase() : null}
                    onChange={refineToggle}
                    currentRefinement={searchState.refinementList || {}}
                    query={query}
                  />
                ))}
                <ExpandButton
                  className="text-white w-full m:w-auto mr-3 m:mr-0"
                  expanded={showRefinements}
                  onClick={switchRefinements}
                >
                  Advanced search
                </ExpandButton>
              </div>
            )}
          </AnimatePresence>
          <CurrentRefinements
            transformItems={(items) =>
              items.filter((item) => item.attribute !== "categories")
            }
          />
          {searchActive && <ResultsCount />}
          <motion.div
            initial={false}
            variants={variants}
            animate={showRefinements && searchActive ? "open" : "closed"}
            className="bg-black bg-opacity-25 overflow-hidden"
          >
            <div className="mx-auto max-w-s m:max-w-m l:max-w-l">
              <RefinementList
                attribute="genres"
                icon={GenresIcon}
                className="justify-start"
                limit={30}
              />
              <RefinementList
                attribute="industries"
                icon={IndustriesIcon}
                className="justify-start"
                limit={30}
              />
              <RefinementList
                attribute="markets"
                icon={MarketsIcon}
                limit={30}
              />
              <RefinementList
                attribute="platforms"
                icon={PlatformsIcon}
                limit={30}
              />
              <RefinementList attribute="shows" icon={ShowsIcon} limit={30} />
              <RefinementList attribute="movies" icon={MoviesIcon} limit={30} />
              <RefinementList attribute="talent" icon={TalentIcon} limit={30} />
              <RefinementList
                attribute="solutions"
                icon={SolutionsIcon}
                limit={30}
              />
              <RefinementList
                attribute="metrics"
                icon={MetricsIcon}
                last
                limit={30}
              />
            </div>
          </motion.div>
        </Header>
        <InsightsCategories
          attribute="categories"
          id="categories-nav"
          onChange={refineToggle}
          queryString={createQueryString(searchState, false)}
          searchActive={searchActive}
          transformItems={(items) =>
            items.filter((item) => item.label !== "Announcements")
          }
          limit={30}
        />
        <div className="bg-grey-95 pb-32">
          <Main>
            <ResultsTitle>
              {resultsTitle}
              {/* {!category && "Driving industry thought leadership"}
              {!!category && `${category.name} `} */}
            </ResultsTitle>
            <Hits infinite={true} settings={data.gcms} />
            <div className="text-center">
              <a href="#header" onClick={scrollToTop}>
                Back to top
                <SwitchArrow
                  active={true}
                  activeColor="black"
                  style={{ marginTop: 8 }}
                />
              </a>
            </div>
            <LoadingIndicator
              loading={settings?.loading}
              noResults={settings?.noResults}
            />
            <div className="pb-8 pt-12">
              <hr className="light" />
            </div>
            {page?.featuredPressArticles?.length > 0 && (
              <FeaturedPressCarousel
                articles={page.featuredPressArticles}
                className="mt-4 mb-24"
              />
            )}
          </Main>
        </div>
        <div className="-mt-8 m:-mt-16" id="subscribe">
          <NewsletterForm />
        </div>
      </InstantSearch>
    </Layout>
  )
}

export default Insights

export const query = graphql`
  query InsightsList($path: String!) {
    gcms {
      pages(where: { OR: [{ path: $path }, { path: "/insights" }] }) {
        ...PageData
      }
      suggestedTags(orderBy: order_ASC) {
        searchQuery
        filter
      }
      insightsCategories(where: { slug_not: "announcements" }) {
        name
        slug
      }
      insightsConfig: websiteSetting(where: { name: "Insights search" }) {
        jsonValue
      }
      titlesSearch: websiteSetting(where: { name: "Titles search" }) {
        jsonValue
      }
      platformsSearch: websiteSetting(where: { name: "Platforms search" }) {
        jsonValue
      }
    }
  }
`
